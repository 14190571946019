import { CustomCellRendererProps } from "ag-grid-react";
import { DECIMAL_2 } from "presentation/utils/numberUtil";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { DiscountMappingConstant } from "./DiscountMappingConstant";

const DISCOUNT_MAPPING_CONSTANT = DiscountMappingConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let numberFieldList: string[] = [];
export const INITIAL_DISCOUNT_MAPPING_COL_DEF: any[] = [

    {
        headerName: DISCOUNT_MAPPING_CONSTANT.PRIORITY,
        field: "priority",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.BILL_TO_COMPANY,
        field: "billToCompanyCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.CHARGE_TYPE,
        field: "chargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.SUB_CHARGE_TYPE,
        field: "subChargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.CHARGE_TAR_TYPE,
        field: "chargeTariffType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.CHARGE_TAR_CODE,
        field: "chargeTariffCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.EFFECTIVE_DATE,
        field: "effectiveDate",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 160,
        dataType: "date",
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.EXPIRY_DATE,
        field: "expiryDate",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 160,
        dataType: "date",
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.CONFIRMED_DATE,
        field: "confirmedDate",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
        dataType: "date",
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.STATUS,
        field: "status",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.REASON,
        field: "reasonCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.DISCOUNT,
        field: "discount",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        dataType: DECIMAL_2,
        width: 120,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.DISCOUNT_TYPE,
        field: "discountType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.DISCOUNT_WITH_TAX,
        field: "discountWithTax",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.DISCOUNT_TAR_TYPE,
        field: "tariffType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.DISCOUNT_TAR_CODE,
        field: "tariffCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: DISCOUNT_MAPPING_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}