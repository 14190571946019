import { SelectionChangedEvent } from "ag-grid-community";
import { DiscountMappingEntity } from "domain/entity/DiscountMapping/DiscountMappingEntity";
import _ from "lodash";
import { AclType } from "presentation/constant/ANAInfo/NbisivPermission";
import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { INITIAL_DISCOUNT_MAPPING_COL_DEF } from "presentation/constant/DiscountMapping/DiscountMappingColumnDefinition";
import { DiscountMappingConstant } from "presentation/constant/DiscountMapping/DiscountMappingConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useDiscountMappingVM } from "presentation/hook/DiscountMapping/useDiscountMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useDiscountMappingTracked } from "presentation/store/DiscountMapping/DiscountMappingProvider";
import { isPermissionExist } from "presentation/utils/permissionUtils";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { NbisIconButton } from "presentation/view/components/NbisIconButton";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
const DiscountMappingTablePanel = () => {
    const gridRef: any = useRef(null);
    const [discountMappingState] = useDiscountMappingTracked();
    const discountMappingVM = useDiscountMappingVM();
    const messageBarVM = useMessageBarVM();
    const { updatedRows, selectedRows } = discountMappingState;
    const DISCOUNT_MAPPING_CONSTANT = DiscountMappingConstant.Title;
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const rightClickRef: any = createRef();
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<DiscountMappingEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);

    const allowReadRej = isPermissionExist(Permission.DISCOUNT_MAPPING_REJECT, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const allowUpdateRej = isPermissionExist(Permission.DISCOUNT_MAPPING_REJECT, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const allowReadApp = isPermissionExist(Permission.DISCOUNT_MAPPING_APPROVE, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const allowUpdateApp = isPermissionExist(Permission.DISCOUNT_MAPPING_APPROVE, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        discountMappingVM.updateSelectedRows(selectedRows);
    }, [discountMappingVM])

    const handleRowDoubleClick = useCallback((entity: DiscountMappingEntity) => {
        discountMappingVM.onRowDoubleClick(entity);
    }, [discountMappingVM])


    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return discountMappingState.selectedRows;
    }, [discountMappingState.selectedRows]);

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(discountMappingState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, discountMappingState.selectedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        discountMappingVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [discountMappingVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(discountMappingState.discountMappingList);
        discountMappingVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [discountMappingState.discountMappingList, discountMappingVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        discountMappingVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showError('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                discountMappingVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                discountMappingVM.searchAllDiscountMappingList().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    discountMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    discountMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [messageBarVM, discountMappingVM]);

    const handleAdd = useCallback(() => {
        discountMappingVM.onAddClick();
    }, [discountMappingVM]);

    const handleUnconfirm = useCallback(async () => {
        if (_.isEmpty(selectedRows)) return;
        setIsLoading(true);
        discountMappingVM.onUnconfirm(selectedRows).then(async (data) => {
            if (data && data.success) {
                messageBarVM.showSuccess('Unconfirm Successfully.');
                await discountMappingVM.searchAllDiscountMappingList().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    discountMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    discountMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            } else {
                messageBarVM.showError(data.data);
                setInitialAllRows(true);
                setIsLoading(false);

                discountMappingVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            }
        });
    }, [discountMappingVM, messageBarVM, selectedRows]);

    const handleSubmit = useCallback(async () => {
        if (_.isEmpty(selectedRows)) return;
        setIsLoading(true);
        discountMappingVM.onSubmit(selectedRows).then(async (data) => {
            if (data && data.success) {
                messageBarVM.showSuccess('Submit Successfully.');
                await discountMappingVM.searchAllDiscountMappingList().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    discountMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    discountMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            } else {
                messageBarVM.showError(data.data);
                setInitialAllRows(true);
                setIsLoading(false);

                discountMappingVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            }
        });
    }, [discountMappingVM, messageBarVM, selectedRows]);

    const handleApprove = useCallback(async () => {
        if (_.isEmpty(selectedRows)) return;
        setIsLoading(true);
        discountMappingVM.onApprove(selectedRows).then(async (data) => {
            if (data && data.success) {
                messageBarVM.showSuccess('Approve Successfully.');
                await discountMappingVM.searchAllDiscountMappingList().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    discountMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    discountMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            } else {
                messageBarVM.showError(data.data);
                setInitialAllRows(true);
                setIsLoading(false);

                discountMappingVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            }
        });
    }, [discountMappingVM, messageBarVM, selectedRows]);

    const handleReject = useCallback(async () => {
        discountMappingVM.onRejectClick();

    }, [discountMappingVM]);


    const isSubmitDisable = () => {
        if (updatedRows.length === 0 && selectedRows.length > 0 && selectedRows.every(entity => entity.status !== 'FINALIZED')) return false
        return true;
    }
    const isUnconfirmDisable = () => {
        if (updatedRows.length === 0 && selectedRows.length > 0 && selectedRows.every(entity => entity.status === 'FINALIZED' && entity.confirmedDate)) return false
        return true;
    }
    const isRejectDisable = () => {
        if (updatedRows.length === 0 && selectedRows.length > 0 && selectedRows.every(entity => entity.status === 'FINALIZED' && !entity.confirmedDate)) return false
        return true;
    }
    const isApproveDisable = () => {
        if (updatedRows.length === 0 && selectedRows.length > 0 && selectedRows.every(entity => entity.status === 'FINALIZED' && !entity.confirmedDate)) return false
        return true;
    }

    const memoDiscountMappingTable = useMemo(() => {

        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
                    <NbisTable
                        id='discount-mapping-table'
                        isNewColumnSetting={true}
                        columns={INITIAL_DISCOUNT_MAPPING_COL_DEF}
                        data={allRows ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={false}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={true}
                        showCutCursor={showMoveCursor}
                        isRowHighligted={true}
                        handleSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: DiscountMappingEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 125px)"
                        ref={gridRef}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                    />
                </div>
            </>
        );
    }, [allRows, allowUpdate, getAllRows, getSelectedRows, handleRowDoubleClick, handleSelectionChange, onMoveCancel, onRefreshRow, onSaveMove, rightClickRef, showMoveCursor])

    useEffect(() => {
        if ((discountMappingState.afterReject || initialAllRows) && discountMappingState.discountMappingList && !_.isEmpty(discountMappingState.discountMappingList)) {
            setIsLoading(true);
            setAllRows(discountMappingState.discountMappingList?.map((taxMappingEntity, index) => ({
                ...taxMappingEntity,
                index: index || 0,
                flag_showCutCursor: false
            })));
            setInitialAllRows(false);
            discountMappingVM.setAfterReject(false);
        }

        setIsLoading(false);
    }, [allRows, initialAllRows, discountMappingState.discountMappingList, discountMappingState.afterReject, discountMappingVM]);

    //if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderTitle>{DISCOUNT_MAPPING_CONSTANT.TITLE}</HeaderTitle>
            {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            <StyledAction className="tm-animated-wrapper">
                <NbisIconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />
                <div className="add-seperator" />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_UNCONFIRM} size={"Small"} theme={"Secondary"} disabled={isUnconfirmDisable()} onClick={handleUnconfirm} />
                <HPHButton label={"Submit"} size={"Small"} theme={"Secondary"} disabled={isSubmitDisable()} onClick={handleSubmit} />
                {((allowReadRej && allowUpdateRej) || (allowReadApp && allowUpdateApp)) && <><div className="add-seperator" />
                    {(allowReadRej && allowUpdateRej) && <HPHButton label={"Reject"} size={"Small"} theme={"Secondary"} disabled={isRejectDisable()} onClick={handleReject} />}
                    {(allowReadApp && allowUpdateApp) && <HPHButton label={"Approve"} size={"Small"} theme={"Primary"} disabled={isApproveDisable()} onClick={handleApprove} />}
                </>}
            </StyledAction>
            {discountMappingState.forceRefresh && <></>}
        </Sidebarheader>

        <TableWrapper>
            {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            {memoDiscountMappingTable}
        </TableWrapper>
    </>;
}

export default memo(DiscountMappingTablePanel);
